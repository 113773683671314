<!-- 添加渠道客户 -->
<template>
  <el-dialog
      width="40%"
      center
      :visible="visible"
      :lock-scroll="false"
      :destroy-on-close="true"
      custom-class="ele-dialog-form"
      :title="isUpdate?'编辑渠道客户':'添加渠道客户'"
      @update:visible="updateVisible">
    <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="130px">
      <el-form-item label="客户名称:" prop="customer_name">
        <el-input
            clearable
            v-model="form.customer_name"
            placeholder="请输入客户名称"/>
      </el-form-item>
      <el-form-item label="姓名:" prop="realname">
        <el-input
            clearable
            v-model="form.realname"
            placeholder="请输入姓名"/>
      </el-form-item>
      <el-form-item label="手机号:" prop="phone">
        <el-input
            clearable
            v-model="form.phone"
            placeholder="请输入手机号"/>
      </el-form-item>
      <!--<el-form-item label="客户:" prop="order_placer">-->
      <!--  <el-input-->
      <!--      clearable-->
      <!--      v-model="form.order_placer"-->
      <!--      placeholder="请输入客户"/>-->
      <!--</el-form-item>-->
      <!--<el-form-item label="客户手机号:" prop="order_placer_phone">-->
      <!--  <el-input-->
      <!--      clearable-->
      <!--      v-model="form.order_placer_phone"-->
      <!--      placeholder="请输入客户手机号"/>-->
      <!--</el-form-item>-->
      <el-form-item label="账号:" prop="account">
        <el-input
            clearable
            v-model="form.account"
            placeholder="请输入账号"/>
      </el-form-item>
      <el-form-item label="密码:" prop="password">
        <el-input
            clearable
            v-model="form.password"
            placeholder="请输入密码"/>
      </el-form-item>
    </el-form>


    <div slot="footer">
      <el-button
          @click="updateVisible(false)">取消
      </el-button>
      <el-button
          style="margin-left: 20px;"
          type="primary"
          :loading="loading"
          @click="save">提交
      </el-button>
    </div>

  </el-dialog>
</template>

<script>
import {Addcustomers, Editcustomers} from "@/api/custom";

export default {
  name: 'UserEdit',
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object
  },
  components: { },
  data() {
    return {
      // 表单数据
      form: Object.assign({}, this.data),
      // 表单验证规则
      rules: {
        customer_name: [
          {required: true, message: '请输入客户名称', trigger: 'blur'},
          { min: 0, max: 58, message: '长度最多输入50个字符', trigger: 'blur' }
        ],
        realname: [
          {required: true, message: '请输入姓名', trigger: 'blur'},
          { min: 0, max: 10, message: '长度最多输入10个字符', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: "请输入正确的手机号码",
            trigger: "blur"
          }
        ],
        order_placer: [
          {required: true, message: '请输入客户', trigger: 'blur'},
          { min: 0, max: 10, message: '长度最多输入10个字符', trigger: 'blur' }
        ],
        order_placer_phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: "请输入正确的手机号码",
            trigger: "blur"
          }
        ],
        account: [
          {required: true, message: '请输入账号', trigger: 'blur'},
          { min: 0, max: 20, message: '长度最多输入20个字符', trigger: 'blur' }
        ],
        password: [
          {required: true, message: '请输入密码', trigger: 'blur'},
          { min: 0, max: 20, message: '长度最多输入20个字符', trigger: 'blur' }
        ],
      },
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,

    };
  },
  watch: {
    data() {
      if (this.data) {
        console.log(this.data)
        this.form = Object.assign({}, this.data);
        this.isUpdate = true;
      } else {
        this.form = {};
        this.isUpdate = false;
      }
    }
  },
  mounted() {

  },
  methods: {
    /* 保存编辑 */
    save() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.loading = true;
          //判断是新增还是修改
          if(this.isUpdate === false){
            Addcustomers(this.form).then(res => {
              console.log(res)
              this.loading = false;
              if (res.code === 200) {
                this.$message.success(res.msg);
                if (!this.isUpdate) {
                  this.form = {};
                }
                this.updateVisible(false);
                this.$emit('done');
              } else {
                this.$message.error(res.msg);
              }
            }).catch(e => {
              this.loading = false;
              this.$message.error(e.msg);
            })
          }else {
            let data = this.form;
            Editcustomers(data.id,data).then(res => {
              console.log(res)
              this.loading = false;
              if (res.code === 200) {
                this.$message.success(res.msg);
                if (!this.isUpdate) {
                  this.form = {};
                }
                this.updateVisible(false);
                this.$emit('done');
              } else {
                this.$message.error(res.msg);
              }
            }).catch(e => {
              this.loading = false;
              this.$message.error(e.msg);
            })
          }
        } else {
          // 聚焦功能
          this.$firstInputFocus();
          return false;
        }
      });
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
    },


  }
}
</script>

<style scoped lang="scss">
</style>
